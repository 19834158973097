export class NavigateRouter {
  public static readonly HOME = '/'

  public static readonly ALL = '*'

  public static readonly OAUTH = '/oauth'

  public static readonly SELLERNET = '/sellernet'

  public static readonly PROFILE = this.SELLERNET + '/profile'

  public static readonly POSTS = this.SELLERNET + '/posts'

  public static readonly REGISTER = this.OAUTH + '/signup'

  public static readonly LOGIN = this.OAUTH + '/signin'

  public static readonly EMAIL_VERIFICATION = this.OAUTH + '/email-verification'

  public static readonly EMAIL_CONFIRM = this.OAUTH + '/email-confirm'

  public static readonly RESEND_VERIFICATION = this.OAUTH + '/resend-verification'

  public static readonly POST_EDIT = this.POSTS + '/:id'

  public static readonly POST_EDIT_ID = (id: string) => this.POSTS + `/${id}`

  public static readonly REAL_ESTATE = '/real-estate'

  public static readonly REAL_ESTATE_FOR_SALE = this.REAL_ESTATE + '/sale'

  public static readonly REAL_ESTATE_FOR_SALE_BY_ID = this.REAL_ESTATE_FOR_SALE + '/:id'

  public static readonly REAL_ESTATE_FOR_SALE_ID = (id: string) => this.REAL_ESTATE_FOR_SALE + `/${id}`

  public static readonly REAL_ESTATE_FOR_RENT = this.REAL_ESTATE + '/rent'

  public static readonly REAL_ESTATE_FOR_RENT_BY_ID = this.REAL_ESTATE_FOR_RENT + '/:id'

  public static readonly REAL_ESTATE_FOR_RENT_ID = (id: string) => this.REAL_ESTATE_FOR_RENT + `/${id}`

  public static readonly NEWS = '/news'

  public static readonly NEWS_BY_ID = (id: string) => this.NEWS + `/${id}`

  public static readonly NEWS_BY_SLUG = (slug: string) => this.NEWS + `/${slug}`

  public static readonly AUTH_GOOGLE_CALLBACK = this.OAUTH + '/auth/google/callback'

  public static readonly GOLD_PRICE = this.NEWS + '/gold-price'

  public static readonly GOLD_PRICE_WORLD = this.GOLD_PRICE + '/world'

  public static readonly GOLD_PRICE_CRYPTO = this.GOLD_PRICE + '/crypto'

  public static readonly NEWS_CATEGORY_ID = this.NEWS + '/:category_id'

  public static readonly NEWS_CATEGORY_BY_ID = (category_id: string) => this.NEWS + `/${category_id}`

  public static readonly NEWS_CATEGORY_BY_CODE = (code: string) => this.NEWS + `/${code}`

  public static readonly NEWS_VIDEOS = this.NEWS + '/videos'

  public static readonly NOT_FOUND = '/not-found'

  public static readonly PRIVACY_POLICY = '/privacy-policy'

  public static readonly NEWS_HOT = this.NEWS + '/hot'
}
